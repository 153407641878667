import { useState } from "react";
import useTranslation from "utils/useTranslation";
import "./RequestAccess.scss";
import { useDispatch, useSelector } from "react-redux";
import { createContact } from "reducers/contacts";
import Spinner from "components/spinner/Spinner";
import Select from "react-select";
import countryList from "assets/countryList";

const inputList = [
  {
    placeholder: "firstname",
    target: "firstname",
    type: "text",
    requested: true,
  },
  {
    placeholder: "lastname",
    target: "lastname",
    type: "text",
    requested: true,
  },
  {
    placeholder: "company",
    target: "companyName",
    type: "text",
    requested: true,
  },
  { placeholder: "email", target: "email", type: "email", requested: true },
  { placeholder: "phone", target: "phone", type: "tel" },
  { placeholder: "mobile", target: "mobile", type: "tel" },
  { placeholder: "address", target: "address", type: "text", requested: true },
  { placeholder: "city", target: "city", type: "text", requested: true },
  { placeholder: "zip", target: "postalCode", type: "text", requested: true },
  { placeholder: "taxcode", target: "taxCode", type: "text" },
  {
    placeholder: "country",
    target: "country",
    type: "text",
    isSelect: true,
    options: countryList,
    requested: true,
  },
  // {
  //   placeholder: "request text",
  //   target: "text",
  //   type: "text",
  //   requested: true,
  // },
];

const RequestAccess = ({ distributorId }) => {
  const [request, setRequest] = useState({});
  const dispatch = useDispatch();

  const handleChangeInput = (target, e) => {
    setRequest({ ...request, [target]: e.target.value });
  };
  const handleChangeSelect = (selectedOption) => {
    setRequest({ ...request, country: selectedOption.value });
  };
  const contact = useSelector(({ contacts }) => contacts.data);
  const loading = useSelector(({ contacts }) => contacts.loading);
  const error = useSelector(({ contacts }) => contacts.error);
  const { t } = useTranslation();

  const handleSubmit = async () => {
    let newRequest = request;
    if (distributorId) {
      newRequest = { ...request, distributorId };
    }
    dispatch(createContact(newRequest));
    setRequest({});
  };

  const isDisabled = () => {
    const requestedInputs = inputList.filter((input) => input.requested);
    const inputStatus = requestedInputs.map(
      (input) => !!request[input.target] && request[input.target] !== ""
    );
    const inputAreValid = inputStatus.every((input) => !!input);
    const checkboxesAreValid = request.hasAcceptedPolicy;
    return !inputAreValid || !checkboxesAreValid;
  };
  // console.log("request", request);
  return (
    <div className="request-access-popup">
      {contact ? (
        <>
          <h3>{t("richiestaInviata", text)}</h3>
          <p style={{ fontSize: "1.2rem" }}>{t("contattato", text)}</p>
        </>
      ) : (
        <>
          <h3>{t("request")}</h3>
          <p>{t("requestText", text)}</p>
          <div
            className="request-access__form"
            style={{ marginBottom: "1rem" }}
          >
            {inputList.map(
              ({ placeholder, target, type, requested, isSelect, options }) => (
                <>
                  {isSelect ? (
                    <Select
                      key={`key_inputlists_${target}`}
                      options={options}
                      onChange={handleChangeSelect}
                      placeholder={
                        requested ? `${t(placeholder)}*` : t(placeholder)
                      }
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: "none",
                          boxShadow: "none",
                          borderBottom: "1px solid var(--blackBorder)",
                          borderRadius: "0",
                          backgroundColor: "transparent",

                          "&:hover": {
                            border: "none",
                            boxShadow: "none",
                            borderBottom: "1px solid var(--blackBorder)",
                          },
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          color: "var(--black)",
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          textAlign: "left",
                          fontFamily: "var(--font1)",
                          letterSpacing: "2px",
                          marginLeft: "-6px",
                        }),
                        menu: (provided, state) => ({
                          ...provided,
                          border: "1px solid var(--blackBorder)",
                          borderRadius: "0",
                          marginTop: "0",
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isFocused
                            ? "var(--lightGray)"
                            : "transparent",
                          color: "var(--black)",
                          textTransform: "uppercase",
                          fontWeight: state.isSelected ? "bold" : "normal",
                          "&:hover": {
                            backgroundColor: "var(--lightGray)",
                          },
                        }),
                        singleValue: (provided, state) => ({
                          ...provided,
                          color: "var(--black)",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          textAlign: "left",
                          fontFamily: "var(--font1)",
                          letterSpacing: "2px",
                          marginLeft: "-6px",
                        }),
                        input: (provided, state) => ({
                          ...provided,
                          color: "var(--black)",
                          textTransform: "uppercase",
                          fontWeight: "bold",
                          fontSize: "0.9rem",
                          textAlign: "left",
                          fontFamily: "var(--font1)",
                          letterSpacing: "2px",
                          marginLeft: "-6px",
                        }),
                      }}
                      target={target}
                      normalFont={true}
                    />
                  ) : (
                    <input
                      key={target}
                      target={target}
                      placeholder={
                        requested ? `${t(placeholder)}*` : t(placeholder)
                      }
                      value={request[target]}
                      type={type}
                      onChange={(e) => {
                        handleChangeInput(target, e);
                      }}
                    />
                  )}
                </>
              )
            )}
          </div>
          <input
            target="text"
            placeholder={`${t("request text")}*`}
            value={request["text"]}
            type="text"
            onChange={(e) => {
              setRequest({ ...request, text: e.target.value });
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="checkbox-form">
              <input
                checked={request.hasAcceptedPolicy}
                onChange={(e) => {
                  setRequest({
                    ...request,
                    hasAcceptedPolicy: e.target.checked,
                  });
                }}
                type="checkbox"
                id="privacy"
              />
              <label htmlFor="privacy">{t("privacy")}</label>
            </div>
            <div
              style={{
                fontSize: "0.8rem",
                fontWeight: 500,
              }}
            >
              {t("campiObbligatori")}
            </div>
          </div>
          {error && (
            <>
              {error.message === "Contact already exists" ? (
                <div className="error">{t("erroreUtentePresente", text)}</div>
              ) : (
                <div className="error">{t("errore", text)}</div>
              )}
            </>
          )}
          <div
            onClick={() => handleSubmit()}
            className={`btn tertiary pad center ${isDisabled() && "disabled"}`}
          >
            {loading ? (
              <>
                <Spinner />
              </>
            ) : (
              <>{t("send")}</>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default RequestAccess;

const text = [
  {
    id: "requestText",
    en: "Fill out the form and send us the request to request access to the Suex Military reserved area",
    it: "Compila il modulo e inviaci la richiesta per richiedere l'accesso all'area riservarta Suex Military",
  },
  {
    id: "richiestaInviata",
    it: "Richiesta inviata con successo",
    en: "Request sent successfully",
  },
  {
    id: "contattato",
    it: "Verrà contattato al più presto tramite email",
    en: "We will contact you as soon as possible via email",
  },
  {
    id: "errore",
    it: "Si è verificato un errore durante l'invio della richiesta, riprova più tardi",
    en: "An error occurred during the request, please try again later",
  },
  {
    id: "erroreUtentePresente",
    it: "L'email è già associata ad un utente o a una richiesta in fase di approvazione",
    en: "The email is already associated to a user or a request in the approval phase",
  },
];
