import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllFirmware, updateFirmware } from "reducers/firmware";
import {
  getAllDocuments,
  getAllDocumentsCourses,
  updateDocument,
} from "reducers/documents";
import { getAllSoftware, updateSoftware } from "reducers/software";
import { getAllPricelist, updatePricelist } from "reducers/priceList";
import useTranslation from "utils/useTranslation";
import moment from "moment-timezone";
import {
  EditButton,
  History,
  Close,
  Save,
  Search,
} from "../../../../assets/icons/icons";
import "./AdminPanel.scss";
import { baseUrl } from "config/server";
import { getAllUsersDownloadHistory } from "reducers/history";
import { Popup } from "components/popup/Popup";
import Spinner from "components/spinner/Spinner";

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const AdminPanelDoc = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const account = useSelector(({ user }) => user.data.user);
  const firmware = useSelector(({ firmware }) => firmware.data);
  const documents = useSelector(({ documents }) => documents.data);
  const documentsCourses = useSelector(
    ({ documents }) => documents.docsCourses
  );
  const software = useSelector(({ software }) => software.data);
  const pricelist = useSelector(({ pricelist }) => pricelist.data);
  const [filterDocumentText, setFilterDocumentText] = useState("");
  const [filterDocumentCoursesText, setFilterDocumentCoursesText] =
    useState("");
  const [filterFirmwareText, setFilterFirmwareText] = useState("");
  const [filterSoftwareText, setFilterSoftwareText] = useState("");
  const [filterPricelistText, setFilterPricelistText] = useState("");
  const [filterHistoryDownloadUser, setFilterHistoryDownloadUser] =
    useState("");
  const [selectedContent, setSelectedContent] = useState(null);
  const usersDownloadHistory = useSelector(({ history }) => history.userList);
  const historyLoading = useSelector(({ history }) => history.loading);

  useEffect(() => {
    if (!!account) {
      dispatch(getAllFirmware());
      dispatch(getAllDocuments());
      dispatch(getAllDocumentsCourses());
      dispatch(getAllSoftware());
      dispatch(getAllPricelist());
    }
  }, [account]);

  useEffect(() => {
    if (selectedContent !== null) {
      // console.log("selectedContent", selectedContent);
      dispatch(
        getAllUsersDownloadHistory({
          contentType: selectedContent.type,
          contentId: selectedContent.id,
        })
      );
    }
  }, [selectedContent]);

  //Raggruppare i firmware con lo stesso firmwareCode
  const firmwareGrouped = firmware.reduce((acc, curr) => {
    const found = acc.find((item) => item.firmwareCode === curr.firmwareCode);
    if (found) {
      found.firmwareUrl = curr.firmwareUrl;
      if (found.productCode.includes(curr.productCode)) return acc;
      found.productCode = found.productCode + " & " + curr.productCode;
      return acc;
    } else {
      return [...acc, curr];
    }
  }, []);

  const translate = (date) => {
    let splittedData = date.split(" ");
    if (splittedData[1] === "January") {
      splittedData[1] = "Gennaio";
    } else if (splittedData[1] === "February") {
      splittedData[1] = "Febbraio";
    } else if (splittedData[1] === "March") {
      splittedData[1] = "Marzo";
    } else if (splittedData[1] === "April") {
      splittedData[1] = "Aprile";
    } else if (splittedData[1] === "May") {
      splittedData[1] = "Maggio";
    } else if (splittedData[1] === "June") {
      splittedData[1] = "Giugno";
    } else if (splittedData[1] === "July") {
      splittedData[1] = "Luglio";
    } else if (splittedData[1] === "August") {
      splittedData[1] = "Agosto";
    } else if (splittedData[1] === "September") {
      splittedData[1] = "Settembre";
    } else if (splittedData[1] === "October") {
      splittedData[1] = "Ottobre";
    } else if (splittedData[1] === "November") {
      splittedData[1] = "Novembre";
    } else if (splittedData[1] === "December") {
      splittedData[1] = "Dicembre";
    }
    return splittedData.join(" ");
  };

  const FilteredDocuments = documents.filter((document) => {
    return (
      document.productCode
        ?.toString()
        .includes(filterDocumentText?.toString()) ||
      document.nameIt?.toLowerCase().includes(filterDocumentText?.toLowerCase())
    );
  });
  const FilteredDocumentsCourses = documentsCourses?.filter((document) => {
    return (
      document.productCode
        ?.toString()
        .includes(filterDocumentCoursesText?.toString()) ||
      document.nameIt
        ?.toLowerCase()
        .includes(filterDocumentCoursesText?.toLowerCase())
    );
  });
  const FilteredSoftware = software.filter((software) => {
    return (
      software.productCode
        ?.toString()
        .includes(filterSoftwareText?.toString()) ||
      software.nameIt?.toLowerCase().includes(filterSoftwareText?.toLowerCase())
    );
  });
  const FilteredFirmware = firmwareGrouped.filter((firmware) => {
    // mostrare solo i firmware che hanno un firmwareCode unico

    return (
      firmware.productCode
        ?.toString()
        .includes(filterFirmwareText?.toString()) ||
      firmware.nameIt?.toLowerCase().includes(filterFirmwareText?.toLowerCase())
    );
  });
  const FilteredPriceList = pricelist.filter((priceList) => {
    return (
      priceList.productCode
        ?.toString()
        .includes(filterPricelistText?.toString()) ||
      priceList.nameIt
        ?.toLowerCase()
        .includes(filterPricelistText?.toLowerCase())
    );
  });
  const FilteredHistoryDownloadUsers = usersDownloadHistory.filter((user) => {
    return (
      user.firstname
        ?.toString()
        .toLowerCase()
        .includes(filterHistoryDownloadUser?.toString().toLowerCase()) ||
      user.lastname
        ?.toString()
        .toLowerCase()
        .includes(filterHistoryDownloadUser?.toString().toLowerCase()) ||
      (user.firstname + " " + user.lastname)
        ?.toString()
        .toLowerCase()
        .includes(filterHistoryDownloadUser?.toString().toLowerCase()) ||
      user.email
        ?.toString()
        .toLowerCase()
        .includes(filterHistoryDownloadUser?.toString().toLowerCase()) ||
      (isIta
        ? translate(moment(user.updatedAt)?.format("DD MMMM YYYY - HH:mm"))
        : moment(user.updatedAt)?.format("DD MMMM YYYY - HH:mm")
      )
        ?.toString()
        .toLowerCase()
        .includes(filterHistoryDownloadUser?.toString().toLowerCase())
    );
  });

  return (
    <div className="admin-panel">
      {selectedContent && (
        <Popup handleClose={() => setSelectedContent(null)}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <h2 style={{ marginBottom: "0", maxWidth: "25%" }}>
              {t("storico", text)}
            </h2>
            <div
              className="admin-panel__title-container admin-panel__title-container__title-search-container"
              style={{ maxWidth: "50%" }}
            >
              <div className="searchbar-container">
                <input
                  type="text"
                  value={filterHistoryDownloadUser}
                  placeholder="search"
                  className="searchbar-container__searchbar-input"
                  onChange={(e) => setFilterHistoryDownloadUser(e.target.value)}
                />
                <Search className="searchbar-container__searchbar-icon" />
              </div>
            </div>
          </div>

          {historyLoading ? (
            <Spinner popupSpinner={true} />
          ) : FilteredHistoryDownloadUsers?.length > 0 ? (
            <div className="history-list">
              {FilteredHistoryDownloadUsers?.length > 0 &&
                FilteredHistoryDownloadUsers?.map((item, i) => (
                  <div
                    className="history-row"
                    key={`key_historydownloads_${item.updatedAt}_${i}`}
                  >
                    <div className="history-row__left">
                      <p>
                        <span className="history-row__left__name">
                          {item.firstname} {item.lastname}
                        </span>
                        <span className="history-row__left__email">
                          {item.email}
                        </span>
                      </p>
                    </div>
                    <div className="history-row__right">
                      <p>
                        {isIta
                          ? translate(
                              moment(item.updatedAt).format(
                                "DD MMMM YYYY - HH:mm"
                              )
                            )
                          : moment(item.updatedAt).format(
                              "DD MMMM YYYY - HH:mm"
                            )}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            "Nessun download effettutato"
          )}
        </Popup>
      )}
      {/* DOCUMENTS ROW */}
      {documents?.length > 0 ? (
        <div
          className="admin-firmware-download"
          style={{ marginBottom: "35px" }}
        >
          <div className="admin-panel__title-container admin-panel__title-container__title-search-container">
            <h3 style={{ margin: "0" }}>{t("elencoDocuments", text)}</h3>
            <div className="searchbar-container">
              <input
                type="text"
                value={filterDocumentText}
                placeholder="search"
                className="searchbar-container__searchbar-input"
                onChange={(e) => setFilterDocumentText(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>
          <div>
            {FilteredDocuments &&
            FilteredDocuments?.length < 6 &&
            FilteredDocuments?.length > 0 ? (
              FilteredDocuments.map((document, i) => (
                <DocsRow
                  singleDocument={document}
                  setSelectedContent={setSelectedContent}
                  selectedContent={selectedContent}
                  key={`key_documents_2_${
                    isIta ? document.nameIt : document.nameEn
                  }_${i}`}
                />
              ))
            ) : FilteredDocuments && FilteredDocuments?.length >= 6 ? (
              <div className="overflow-pagination">
                {FilteredDocuments.map((document, i) => (
                  <DocsRow
                    singleDocument={document}
                    setSelectedContent={setSelectedContent}
                    selectedContent={selectedContent}
                    key={`key_documents_3_${
                      isIta ? document.nameIt : document.nameEn
                    }_${i + 10}`}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{t("nessunRisultato", text)}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* DOCUMENTS COURSES ROW */}
      {documentsCourses?.length > 0 ? (
        <div
          className="admin-firmware-download"
          style={{ marginBottom: "35px" }}
        >
          <div className="admin-panel__title-container admin-panel__title-container__title-search-container">
            <h3 style={{ margin: "0" }}>{t("elencoDocumentsCourses", text)}</h3>
            <div className="searchbar-container">
              <input
                type="text"
                value={filterDocumentCoursesText}
                placeholder="search"
                className="searchbar-container__searchbar-input"
                onChange={(e) => setFilterDocumentCoursesText(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>
          <div>
            {FilteredDocumentsCourses &&
            FilteredDocumentsCourses?.length < 6 &&
            FilteredDocumentsCourses?.length > 0 ? (
              FilteredDocumentsCourses.map((document, i) => (
                <DocsRow
                  singleDocument={document}
                  setSelectedContent={setSelectedContent}
                  selectedContent={selectedContent}
                  key={`key_docuemnts_4_${
                    isIta ? document.nameIt : document.nameEn
                  }_${i}`}
                />
              ))
            ) : FilteredDocumentsCourses &&
              FilteredDocumentsCourses?.length >= 6 ? (
              <div className="overflow-pagination">
                {FilteredDocumentsCourses.map((document, i) => (
                  <DocsRow
                    singleDocument={document}
                    setSelectedContent={setSelectedContent}
                    selectedContent={selectedContent}
                    key={`key_documents_5_${
                      isIta ? document.nameIt : document.nameEn
                    }_${i + 10}`}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{t("nessunRisultato", text)}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* FIRMWARE ROW */}
      {firmware?.length > 0 ? (
        <div
          className="admin-firmware-download"
          style={{ marginBottom: "35px" }}
        >
          <div className="admin-panel__title-container admin-panel__title-container__title-search-container">
            <h3 style={{ margin: "0" }}>{t("elencoFirmware", text)}</h3>
            <div className="searchbar-container">
              <input
                type="text"
                value={filterFirmwareText}
                placeholder="search"
                className="searchbar-container__searchbar-input"
                onChange={(e) => setFilterFirmwareText(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>
          <div>
            {FilteredFirmware &&
            FilteredFirmware?.length < 6 &&
            FilteredFirmware?.length > 0 ? (
              FilteredFirmware?.map((firmware) => (
                <FirmwareRow
                  key={`key_firmware_${firmware.id}`}
                  firmware={firmware}
                  setSelectedContent={setSelectedContent}
                  selectedContent={selectedContent}
                />
              ))
            ) : FilteredFirmware && FilteredFirmware?.length >= 6 ? (
              <div className="admin-firmware-pagination">
                {FilteredFirmware?.map((firmware) => (
                  <FirmwareRow
                    key={`key_firmware_2_${firmware.id}`}
                    firmware={firmware}
                    setSelectedContent={setSelectedContent}
                    selectedContent={selectedContent}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{t("nessunRisultato", text)}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* SOFTWARE ROW */}
      {software?.length > 0 ? (
        <div
          className="admin-software-download"
          style={{ marginBottom: "35px" }}
        >
          <div className="admin-panel__title-container admin-panel__title-container__title-search-container">
            <h3 style={{ margin: "0" }}>{t("elencoSoftware", text)}</h3>
            <div className="searchbar-container">
              <input
                type="text"
                value={filterSoftwareText}
                placeholder="search"
                className="searchbar-container__searchbar-input"
                onChange={(e) => setFilterSoftwareText(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>
          <div>
            {FilteredSoftware &&
            FilteredSoftware?.length < 6 &&
            FilteredSoftware?.length > 0 ? (
              FilteredSoftware?.map((software) => (
                <SoftwareRow
                  key={`key_software_${software.id}`}
                  software={software}
                  setSelectedContent={setSelectedContent}
                  selectedContent={selectedContent}
                />
              ))
            ) : FilteredSoftware && FilteredSoftware?.length >= 6 ? (
              <div className="admin-software-pagination">
                {FilteredSoftware?.map((software) => (
                  <SoftwareRow
                    key={`key_software_2_${software.id}`}
                    software={software}
                    setSelectedContent={setSelectedContent}
                    selectedContent={selectedContent}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{t("nessunRisultato", text)}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* PRICElIST ROW */}
      {pricelist?.length > 0 ? (
        <div
          className="admin-pricelist-download"
          style={{ marginBottom: "35px" }}
        >
          <div className="admin-panel__title-container admin-panel__title-container__title-search-container">
            <h3 style={{ margin: "0" }}>{t("elencoPricelist", text)}</h3>
            <div className="searchbar-container">
              <input
                type="text"
                value={filterPricelistText}
                placeholder="search"
                className="searchbar-container__searchbar-input"
                onChange={(e) => setFilterPricelistText(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>
          <div>
            {FilteredPriceList &&
            FilteredPriceList?.length < 6 &&
            FilteredPriceList?.length > 0 ? (
              FilteredPriceList?.map((pricelist) => (
                <PricelistRow
                  key={`key_pricelist_${pricelist.id}`}
                  pricelist={pricelist}
                  setSelectedContent={setSelectedContent}
                  selectedContent={selectedContent}
                />
              ))
            ) : FilteredPriceList && FilteredPriceList?.length >= 6 ? (
              <div className="overflow-pagination">
                {FilteredPriceList?.map((pricelist) => (
                  <PricelistRow
                    key={`key_pricelist_2_${pricelist.id}`}
                    pricelist={pricelist}
                    setSelectedContent={setSelectedContent}
                    selectedContent={selectedContent}
                  />
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p>{t("nessunRisultato", text)}</p>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AdminPanelDoc;

//* FIRMWARE ROW
const FirmwareRow = ({ firmware, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const [file, setFile] = useState(null);
  const [updateActive, setUpdateActive] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [singleFirmware, setSingleFirmware] = useState(firmware);

  const timestamp = () => {
    return new Date().getTime();
  };

  const translate = (date) => {
    let splittedData = date.split(" ");
    if (splittedData[1] === "January") {
      splittedData[1] = "Gennaio";
    } else if (splittedData[1] === "February") {
      splittedData[1] = "Febbraio";
    } else if (splittedData[1] === "March") {
      splittedData[1] = "Marzo";
    } else if (splittedData[1] === "April") {
      splittedData[1] = "Aprile";
    } else if (splittedData[1] === "May") {
      splittedData[1] = "Maggio";
    } else if (splittedData[1] === "June") {
      splittedData[1] = "Giugno";
    } else if (splittedData[1] === "July") {
      splittedData[1] = "Luglio";
    } else if (splittedData[1] === "August") {
      splittedData[1] = "Agosto";
    } else if (splittedData[1] === "September") {
      splittedData[1] = "Settembre";
    } else if (splittedData[1] === "October") {
      splittedData[1] = "Ottobre";
    } else if (splittedData[1] === "November") {
      splittedData[1] = "Novembre";
    } else if (splittedData[1] === "December") {
      splittedData[1] = "Dicembre";
    }
    return splittedData.join(" ");
  };

  const handleSubmit = async (e) => {
    await dispatch(
      updateFirmware(firmware.firmwareCode, {
        uploadFile: file || null,
        nameIt: singleFirmware.nameIt,
        nameEn: singleFirmware.nameEn,
      })
    );
    setUpdateActive(false);
    setEditMode(false);
  };

  const handleChangeInput = async (target, e) => {
    setSingleFirmware({
      ...singleFirmware,
      [target]: e.target.value,
    });
  };

  const handleOnChange = async (e) => {
    const file =
      document.querySelector("#fileInput") &&
      document.querySelector("#fileInput").files
        ? document.querySelector("#fileInput").files
        : "";
    const fileBase64 = await toBase64(file[0]);
    setFile(fileBase64);
  };

  return (
    <div className="download-row">
      <div className="row-container space-between">
        <div
          className="flex-row"
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* NOME */}
          {!editMode ? (
            <p style={{ width: "40%" }}>
              <a
                href={`${baseUrl}/${firmware.firmwareUrl}?ts=${timestamp()}`}
                rel="noreferrer"
                target={"_blank"}
              >
                {isIta ? firmware.nameIt : firmware.nameEn}
              </a>
            </p>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "75px",
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }}
              >
                {/* INPUT NOME ITALIANO */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>ITA</span>
                  <input
                    target={"nameIt"}
                    value={singleFirmware.nameIt}
                    type={"text"}
                    onChange={(e) => {
                      handleChangeInput("nameIt", e);
                    }}
                    style={{
                      height: "25px",
                      width: "calc(100% - 50px)",
                      textIndent: "10px",
                    }}
                  />
                </div>
                {/* INPUT NOME INGLESE */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>ENG</span>
                  <input
                    target={"nameEn"}
                    value={singleFirmware.nameEn}
                    type={"text"}
                    onChange={(e) => {
                      handleChangeInput("nameEn", e);
                    }}
                    style={{
                      height: "25px",
                      width: "calc(100% - 50px)",
                      textIndent: "10px",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "10%",
                }}
              >
                <Save className="save-button" onClick={() => handleSubmit()} />
              </div>
            </div>
          )}
          {/* CODICE PRODOTTO */}
          {!updateActive && !editMode ? (
            <p style={{ width: "15%" }}>
              {firmware.productCode
                ? t("codiceProdotto", text) + firmware.productCode
                : t("documentoCorso", text)}
            </p>
          ) : (
            ""
          )}
          {/* CATEGORIA */}
          {!updateActive && !editMode ? (
            <p style={{ width: "20%" }}>
              {isIta ? firmware.categoryIt : firmware.categoryEn}
            </p>
          ) : (
            ""
          )}
          {/* ULTIMA MODIFICA */}
          {!updateActive && !editMode ? (
            <p style={{ width: "20%" }}>
              {isIta
                ? translate(
                    moment(firmware.updatedAt).format("DD MMMM YYYY - HH:mm")
                  )
                : moment(firmware.updatedAt).format("DD MMMM YYYY - HH:mm")}
            </p>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          {/* BUTTON STORICO */}
          <div className="history-button">
            {!editMode && (
              <History
                onClick={() =>
                  props.setSelectedContent({
                    type: "firmware",
                    id: firmware.id,
                  })
                }
              />
            )}
          </div>
          {/* BUTTON MODIFICA */}
          <div className="edit-button">
            {!editMode ? (
              <EditButton
                onClick={() => {
                  setEditMode(true);
                  setUpdateActive(false);
                }}
              />
            ) : (
              <Close onClick={() => setEditMode(false)} />
            )}
          </div>
          {/* BTN UPDATE */}
          {!updateActive ? (
            <div
              style={{ maxWidth: "132px" }}
              className="btn tertiary pad hover"
              onClick={() => {
                setUpdateActive(true);
                setEditMode(false);
              }}
            >
              {t("aggiorna", text)}
            </div>
          ) : (
            <div className="flex-row justify-end align-center">
              <Close
                onClick={() => setUpdateActive(false)}
                style={{ cursor: "pointer", marginRight: "15px" }}
              />
              <input
                style={{ padding: "2px 0", maxWidth: "250px" }}
                type="file"
                id="fileInput"
                onChange={(e) => handleOnChange(e)}
              />
              <div
                onClick={() => handleSubmit()}
                className="btn tertiary pad hover"
              >
                {isIta ? "Carica File" : "Upload File"}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
//* DOCUMENTS ROW
const DocsRow = ({ singleDocument, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const [file, setFile] = useState(null);
  const [updateActive, setUpdateActive] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [singleDocumentValue, setSingleDocumentValue] =
    useState(singleDocument);

  const timestamp = () => {
    return new Date().getTime();
  };

  const translate = (date) => {
    let splittedData = date.split(" ");
    if (splittedData[1] === "January") {
      splittedData[1] = "Gennaio";
    } else if (splittedData[1] === "February") {
      splittedData[1] = "Febbraio";
    } else if (splittedData[1] === "March") {
      splittedData[1] = "Marzo";
    } else if (splittedData[1] === "April") {
      splittedData[1] = "Aprile";
    } else if (splittedData[1] === "May") {
      splittedData[1] = "Maggio";
    } else if (splittedData[1] === "June") {
      splittedData[1] = "Giugno";
    } else if (splittedData[1] === "July") {
      splittedData[1] = "Luglio";
    } else if (splittedData[1] === "August") {
      splittedData[1] = "Agosto";
    } else if (splittedData[1] === "September") {
      splittedData[1] = "Settembre";
    } else if (splittedData[1] === "October") {
      splittedData[1] = "Ottobre";
    } else if (splittedData[1] === "November") {
      splittedData[1] = "Novembre";
    } else if (splittedData[1] === "December") {
      splittedData[1] = "Dicembre";
    }
    return splittedData.join(" ");
  };

  const handleSubmit = async (e) => {
    await dispatch(
      updateDocument(singleDocument.documentCode, {
        uploadFile: file || null,
        nameIt: singleDocumentValue.nameIt,
        nameEn: singleDocumentValue.nameEn,
      })
    );
    setUpdateActive(false);
    setEditMode(false);
  };

  const handleChangeInput = async (target, e) => {
    setSingleDocumentValue({
      ...singleDocumentValue,
      [target]: e.target.value,
    });
  };

  const handleOnChange = async (e) => {
    const file =
      document.querySelector("#fileInput") &&
      document.querySelector("#fileInput").files
        ? document.querySelector("#fileInput").files
        : "";
    const fileBase64 = await toBase64(file[0]);
    setFile(fileBase64);
  };
  return (
    <div className="download-row">
      <div className="row-container">
        <div
          className="flex-row"
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* NOME */}
          {!editMode ? (
            <p
              style={{
                width: "40%",
              }}
            >
              <a
                href={`${baseUrl}/${
                  singleDocument.documentUrl
                }?ts=${timestamp()}`}
                rel="noreferrer"
                target={"_blank"}
              >
                {isIta ? singleDocument.nameIt : singleDocument.nameEn}
              </a>
            </p>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "75px",
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }}
              >
                {/* INPUT NOME ITALIANO */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>ITA</span>
                  <input
                    target={"nameIt"}
                    value={singleDocumentValue.nameIt}
                    type={"text"}
                    onChange={(e) => {
                      handleChangeInput("nameIt", e);
                    }}
                    style={{
                      height: "25px",
                      width: "calc(100% - 50px)",
                      textIndent: "10px",
                    }}
                  />
                </div>
                {/* INPUT NOME INGLESE */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>ENG</span>
                  <input
                    target={"nameEn"}
                    value={singleDocumentValue.nameEn}
                    type={"text"}
                    onChange={(e) => {
                      handleChangeInput("nameEn", e);
                    }}
                    style={{
                      height: "25px",
                      width: "calc(100% - 50px)",
                      textIndent: "10px",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "10%",
                }}
              >
                <Save className="save-button" onClick={() => handleSubmit()} />
              </div>
            </div>
          )}
          {/* CODICE PRODOTTO */}
          {!updateActive && !editMode ? (
            <p style={{ width: "15%" }}>
              {singleDocument.productCode
                ? t("codiceProdotto", text) + singleDocument.productCode
                : t("documentoCorso", text)}
            </p>
          ) : (
            ""
          )}
          {/* CATEGORIA */}
          {!updateActive && !editMode ? (
            <p style={{ width: "20%" }}>
              {isIta ? singleDocument.categoryIt : singleDocument.categoryEn}
            </p>
          ) : (
            ""
          )}
          {/* ULTIMA MODIFICA */}
          {!updateActive && !editMode ? (
            <p style={{ width: "20%" }}>
              {isIta
                ? translate(
                    moment(singleDocument.updatedAt)
                      .tz("Europe/Rome")
                      .format("DD MMMM YYYY - HH:mm")
                  )
                : moment(singleDocument.updatedAt)
                    .tz("Europe/Rome")
                    .format("DD MMMM YYYY - HH:mm")}
            </p>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          {/* BUTTON STORICO */}
          <div className="history-button">
            {!editMode && (
              <History
                onClick={() =>
                  props.setSelectedContent({
                    type: "document",
                    id: singleDocument.id,
                  })
                }
              />
            )}
          </div>
          {/* BUTTON MODIFICA */}
          <div className="edit-button">
            {!editMode ? (
              <EditButton
                onClick={() => {
                  setEditMode(true);
                  setUpdateActive(false);
                }}
              />
            ) : (
              <Close onClick={() => setEditMode(false)} />
            )}
          </div>
          {/* BTN UPDATE */}
          {!updateActive ? (
            <div
              style={{ maxWidth: "132px" }}
              className="btn tertiary pad hover"
              onClick={() => {
                setUpdateActive(true);
                setEditMode(false);
              }}
            >
              {t("aggiorna", text)}
            </div>
          ) : (
            <div className="flex-row justify-end align-center">
              <Close
                onClick={() => setUpdateActive(false)}
                style={{ cursor: "pointer", marginRight: "15px" }}
              />
              <input
                style={{ padding: "2px 0", maxWidth: "250px" }}
                type="file"
                id="fileInput"
                onChange={(e) => handleOnChange(e)}
              />
              <div
                onClick={() => handleSubmit()}
                className="btn tertiary pad hover"
              >
                {isIta ? "Carica File" : "Upload File"}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
//* SOFTWARE ROW
const SoftwareRow = ({ software, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const [file, setFile] = useState(null);
  const [updateActive, setUpdateActive] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [softwareValue, setSoftwareValue] = useState(software);

  const timestamp = () => {
    return new Date().getTime();
  };

  const translate = (date) => {
    let splittedData = date.split(" ");
    if (splittedData[1] === "January") {
      splittedData[1] = "Gennaio";
    } else if (splittedData[1] === "February") {
      splittedData[1] = "Febbraio";
    } else if (splittedData[1] === "March") {
      splittedData[1] = "Marzo";
    } else if (splittedData[1] === "April") {
      splittedData[1] = "Aprile";
    } else if (splittedData[1] === "May") {
      splittedData[1] = "Maggio";
    } else if (splittedData[1] === "June") {
      splittedData[1] = "Giugno";
    } else if (splittedData[1] === "July") {
      splittedData[1] = "Luglio";
    } else if (splittedData[1] === "August") {
      splittedData[1] = "Agosto";
    } else if (splittedData[1] === "September") {
      splittedData[1] = "Settembre";
    } else if (splittedData[1] === "October") {
      splittedData[1] = "Ottobre";
    } else if (splittedData[1] === "November") {
      splittedData[1] = "Novembre";
    } else if (splittedData[1] === "December") {
      splittedData[1] = "Dicembre";
    }
    return splittedData.join(" ");
  };

  const handleSubmit = async (e) => {
    await dispatch(
      updateSoftware(software.softwareCode, {
        uploadFile: file || null,
        nameIt: softwareValue.nameIt,
        nameEn: softwareValue.nameEn,
      })
    );
    setUpdateActive(false);
    setEditMode(false);
  };

  const handleChangeInput = async (target, e) => {
    setSoftwareValue({
      ...softwareValue,
      [target]: e.target.value,
    });
  };

  const handleOnChange = async (e) => {
    const file =
      document.querySelector("#fileInput") &&
      document.querySelector("#fileInput").files
        ? document.querySelector("#fileInput").files
        : "";
    const fileBase64 = await toBase64(file[0]);
    setFile(fileBase64);
  };
  return (
    <div className="download-row">
      <div className="row-container">
        <div
          className="flex-row justify-between"
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* NOME */}
          {!editMode ? (
            <p style={{ width: "40%" }}>
              <a
                href={`${baseUrl}/${software.softwareUrl}?ts=${timestamp()}`}
                rel="noreferrer"
                target={"_blank"}
              >
                {isIta ? software.nameIt : software.nameEn}
              </a>
            </p>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "75px",
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }}
              >
                {/* INPUT NOME ITALIANO */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>ITA</span>
                  <input
                    target={"nameIt"}
                    value={softwareValue.nameIt}
                    type={"text"}
                    onChange={(e) => {
                      handleChangeInput("nameIt", e);
                    }}
                    style={{
                      height: "25px",
                      width: "calc(100% - 50px)",
                      textIndent: "10px",
                    }}
                  />
                </div>
                {/* INPUT NOME INGLESE */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>ENG</span>
                  <input
                    target={"nameEn"}
                    value={softwareValue.nameEn}
                    type={"text"}
                    onChange={(e) => {
                      handleChangeInput("nameEn", e);
                    }}
                    style={{
                      height: "25px",
                      width: "calc(100% - 50px)",
                      textIndent: "10px",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "10%",
                }}
              >
                <Save className="save-button" onClick={() => handleSubmit()} />
              </div>
            </div>
          )}
          {/* CODICE PRODOTTO */}
          {!updateActive && !editMode ? (
            <p style={{ width: "15%" }}>
              {software.productCode
                ? t("codiceProdotto", text) + software.productCode
                : t("documentoCorso", text)}
            </p>
          ) : (
            ""
          )}
          {/* CATEGORIA */}
          {!updateActive && !editMode ? (
            <p style={{ width: "20%" }}>
              {isIta ? software.categoryIt : software.categoryEn}
            </p>
          ) : (
            ""
          )}
          {/* ULTIMA MODIFICA */}
          {!updateActive && !editMode ? (
            <p style={{ width: "20%" }}>
              {isIta
                ? translate(
                    moment(software.updatedAt).format("DD MMMM YYYY - HH:mm")
                  )
                : moment(software.updatedAt).format("DD MMMM YYYY - HH:mm")}
            </p>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          {/* BUTTON STORICO */}
          <div className="history-button">
            {!editMode && (
              <History
                onClick={() =>
                  props.setSelectedContent({
                    type: "software",
                    id: software.id,
                  })
                }
              />
            )}
          </div>
          {/* BUTTON MODIFICA */}
          <div className="edit-button">
            {!editMode ? (
              <EditButton
                onClick={() => {
                  setEditMode(true);
                  setUpdateActive(false);
                }}
              />
            ) : (
              <Close onClick={() => setEditMode(false)} />
            )}
          </div>
          {/* BTN UPDATE */}
          {!updateActive ? (
            <div
              style={{ maxWidth: "132px" }}
              className="btn tertiary pad hover"
              onClick={() => {
                setUpdateActive(true);
                setEditMode(false);
              }}
            >
              {t("aggiorna", text)}
            </div>
          ) : (
            <div className="flex-row justify-end align-center">
              <Close
                onClick={() => setUpdateActive(false)}
                style={{ cursor: "pointer", marginRight: "15px" }}
              />
              <input
                style={{ padding: "2px 0", maxWidth: "250px" }}
                type="file"
                id="fileInput"
                onChange={(e) => handleOnChange(e)}
              />
              <div
                onClick={() => handleSubmit()}
                className="btn tertiary pad hover"
              >
                {isIta ? "Carica File" : "Upload File"}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
//* PRICE LIST ROW
const PricelistRow = ({ pricelist, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const user = useSelector(({ user }) => user.data.user);
  const [file, setFile] = useState(null);
  const [updateActive, setUpdateActive] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [singlePricelist, setSinglePricelist] = useState(pricelist);

  const timestamp = () => {
    return new Date().getTime();
  };

  const translate = (date) => {
    let splittedData = date.split(" ");
    if (splittedData[1] === "January") {
      splittedData[1] = "Gennaio";
    } else if (splittedData[1] === "February") {
      splittedData[1] = "Febbraio";
    } else if (splittedData[1] === "March") {
      splittedData[1] = "Marzo";
    } else if (splittedData[1] === "April") {
      splittedData[1] = "Aprile";
    } else if (splittedData[1] === "May") {
      splittedData[1] = "Maggio";
    } else if (splittedData[1] === "June") {
      splittedData[1] = "Giugno";
    } else if (splittedData[1] === "July") {
      splittedData[1] = "Luglio";
    } else if (splittedData[1] === "August") {
      splittedData[1] = "Agosto";
    } else if (splittedData[1] === "September") {
      splittedData[1] = "Settembre";
    } else if (splittedData[1] === "October") {
      splittedData[1] = "Ottobre";
    } else if (splittedData[1] === "November") {
      splittedData[1] = "Novembre";
    } else if (splittedData[1] === "December") {
      splittedData[1] = "Dicembre";
    }
    return splittedData.join(" ");
  };

  const handleSubmit = async (e) => {
    await dispatch(
      updatePricelist(pricelist.pricelistCode, {
        uploadFile: file || null,
        nameIt: singlePricelist.nameIt,
        nameEn: singlePricelist.nameEn,
        updatedBy: user.userId,
      })
    );
    setUpdateActive(false);
    setEditMode(false);
  };

  const handleChangeInput = async (target, e) => {
    setSinglePricelist({
      ...singlePricelist,
      [target]: e.target.value,
    });
  };

  const handleOnChange = async (e) => {
    const file =
      document.querySelector("#fileInput") &&
      document.querySelector("#fileInput").files
        ? document.querySelector("#fileInput").files
        : "";
    const fileBase64 = await toBase64(file[0]);
    setFile(fileBase64);
  };

  return (
    <div className="download-row">
      <div className="row-container">
        <div
          className="flex-row justify-between"
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* NOME */}
          {!editMode ? (
            <p style={{ width: "40%" }}>
              <a
                href={`${baseUrl}/${pricelist.pricelistUrl}?ts=${timestamp()}`}
                rel="noreferrer"
                target={"_blank"}
              >
                {isIta ? pricelist.nameIt : pricelist.nameEn}
              </a>
            </p>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "75px",
                  width: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                  flexDirection: "column",
                }}
              >
                {/* INPUT NOME ITALIANO */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>ITA</span>
                  <input
                    target={"nameIt"}
                    value={singlePricelist.nameIt}
                    type={"text"}
                    onChange={(e) => {
                      handleChangeInput("nameIt", e);
                    }}
                    style={{
                      height: "25px",
                      width: "calc(100% - 50px)",
                      textIndent: "10px",
                    }}
                  />
                </div>
                {/* INPUT NOME INGLESE */}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span>ENG</span>
                  <input
                    target={"nameEn"}
                    value={singlePricelist.nameEn}
                    type={"text"}
                    onChange={(e) => {
                      handleChangeInput("nameEn", e);
                    }}
                    style={{
                      height: "25px",
                      width: "calc(100% - 50px)",
                      textIndent: "10px",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "10%",
                }}
              >
                <Save className="save-button" onClick={() => handleSubmit()} />
              </div>
            </div>
          )}
          {/* CODICE PRODOTTO */}
          {!updateActive && !editMode ? (
            <p style={{ width: "15%" }}>
              {pricelist.pricelistCode
                ? t("codiceProdotto", text) + pricelist.pricelistCode
                : t("documentoCorso", text)}
            </p>
          ) : (
            ""
          )}
          <p style={{ width: "20%" }}></p>
          {/* ULTIMA MODIFICA */}
          {!updateActive && !editMode ? (
            <p style={{ width: "20%" }}>
              {isIta
                ? translate(
                    moment(pricelist.updatedAt).format("DD MMMM YYYY - HH:mm")
                  )
                : moment(pricelist.updatedAt).format("DD MMMM YYYY - HH:mm")}
            </p>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            width: "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          {/* BUTTON STORICO */}
          <div className="history-button">
            {!editMode && (
              <History
                onClick={() =>
                  props.setSelectedContent({
                    type: "pricelist",
                    id: pricelist.id,
                  })
                }
              />
            )}
          </div>
          {/* BUTTON MODIFICA */}
          <div className="edit-button">
            {!editMode ? (
              <EditButton
                onClick={() => {
                  setEditMode(true);
                  setUpdateActive(false);
                }}
              />
            ) : (
              <Close onClick={() => setEditMode(false)} />
            )}
          </div>
          {/* BTN UPDATE */}
          {!updateActive ? (
            <div
              style={{ maxWidth: "132px" }}
              className="btn tertiary pad hover"
              onClick={() => {
                setUpdateActive(true);
                setEditMode(false);
              }}
            >
              {t("aggiorna", text)}
            </div>
          ) : (
            <div className="flex-row justify-end align-center">
              <Close
                onClick={() => setUpdateActive(false)}
                style={{ cursor: "pointer", marginRight: "15px" }}
              />
              <input
                style={{ padding: "2px 0", maxWidth: "250px" }}
                type="file"
                id="fileInput"
                accept=".zip"
                onChange={(e) => handleOnChange(e)}
              />
              <div
                onClick={() => handleSubmit()}
                className="btn tertiary pad hover"
              >
                {isIta ? "Carica File" : "Upload File"}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const text = [
  {
    id: "elencoFirmware",
    it: "Elenco Firmware",
    en: "Firmware List",
  },
  {
    id: "elencoDocuments",
    it: "Elenco Documenti Utente",
    en: "Document User List",
  },
  {
    id: "elencoDocumentsCourses",
    it: "Elenco Documenti Corsi",
    en: "Document List Courses",
  },
  {
    id: "elencoSoftware",
    it: "Elenco Software",
    en: "Software List",
  },
  {
    id: "elencoPricelist",
    it: "Listino Prezzi",
    en: "Price List",
  },
  {
    id: "codiceProdotto",
    it: "Cod: ",
    en: "Cod: ",
  },
  {
    id: "documentoCorso",
    it: "Documento Corso",
    en: "Document Course",
  },
  {
    id: "utentiAttesa",
    it: "Utenti in attesa di approvazione",
    en: "Users waiting for approval",
  },
  {
    id: "ultimaModifica",
    it: "Ultima Modifica: ",
    en: "Last Update: ",
  },
  {
    id: "aggiorna",
    it: "Aggiorna",
    en: "Update",
  },
  {
    id: "nessunRisultato",
    it: "Nessun risultato trovato",
    en: "No results found",
  },
];
