import "./Account.scss";
import {
  NavLink,
  Route,
  Switch,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import Course from "./courses/course/Course";
import Courses from "./courses/Courses";
import Info from "./info/Info";
import Download from "./download/Download";
import AdminPanel from "./adminPanel/AdminPanel";
import AdminPanelDoc from "./adminPanel/AdminPanelDoc";

import useTranslation from "utils/useTranslation";
import { useDispatch, useSelector } from "react-redux";
import PermissionDenied from "../permission-denied-page/PermissionDeniedPage";
import { useEffect } from "react";
import { getUser, getUsersByDistributorId } from "reducers/user";

const routes = [
  { path: "/info", component: Info, minLevel: 1 },
  { path: "/download", component: Download, minLevel: 1 },
  { path: "/courses/:courseCode", component: Course, minLevel: 3 },
  { path: "/courses", component: Courses, minLevel: 3 },
  { path: "/admin-panel/users", component: AdminPanel, minLevel: 1000 },
  { path: "/admin-panel/documents", component: AdminPanelDoc, minLevel: 1000 },
];

const Account = () => {
  const user = useSelector(({ user }) => user.data.user);
  const userList = useSelector(({ user }) => user.userList);
  const isAdmin = user?.role === "admin";
  let { path, url } = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) {
      // console.log("get user from account");
      dispatch(getUser());
    }

    if (user?.role !== "admin") {
      dispatch(getUsersByDistributorId({ distributorId: user.userId }));
    }
  }, []);

  return (
    <div className="account-page account-container">
      <div className="account-page__heading">
        <h1 className="border">
          {isAdmin ? t("gestioneUtente", text) : t("account")}
        </h1>
        <div className="account-page__nav-links">
          {links.map(({ to, title, minLevel }, i) => (
            <>
              {(user?.userLevel >= minLevel ||
                user?.role === "admin" ||
                (userList?.length > 0 && title === "gestioneUtenti")) && (
                <NavLink
                  key={`key_links_${i}`}
                  className="btn secondary pad dark"
                  activeClassName="primary"
                  to={url + to}
                >
                  {t(title, text)}
                </NavLink>
              )}
            </>
          ))}
        </div>
      </div>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={`key_route_2_${index}`}
            path={`${path}${route.path}`}
            component={
              user?.userLevel >= route.minLevel ||
              user?.role === "admin" ||
              (userList?.length > 0 && route.path === "/admin-panel/users")
                ? route.component
                : PermissionDenied
            }
          />
        ))}
        <Redirect to={`${url}/info`} />
      </Switch>
    </div>
  );
};

export default Account;

const links = [
  {
    title: "info",
    to: "/info",
    minLevel: 1,
  },
  {
    title: "download",
    to: "/download",
    minLevel: 1,
  },
  {
    title: "courses",
    to: "/courses",
    minLevel: 3,
  },
  {
    title: "gestioneUtenti",
    to: "/admin-panel/users",
    minLevel: 1000,
  },
  {
    title: "gestioneDocumenti",
    to: "/admin-panel/documents",
    minLevel: 1000,
  },
];

const text = [
  {
    id: "info",
    en: "info",
    it: "info",
  },
  {
    id: "download",
    en: "download",
    it: "scarica",
  },
  {
    id: "courses",
    en: "courses",
    it: "corsi",
  },
  {
    id: "adminPanel",
    en: "Administation Panel",
    it: "Pannello di amministrazione",
  },
  {
    id: "gestioneUtenti",
    en: "Users Management",
    it: "Gestione utenti",
  },
  {
    id: "gestioneUtente",
    en: "User Management",
    it: "Gestione utente",
  },
  {
    id: "gestioneDocumenti",
    en: "Documents Management",
    it: "Gestione documenti",
  },
];
