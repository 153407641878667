import Spinner from "components/spinner/Spinner";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createRenewContact } from "reducers/contacts";
import {
  checkEmail,
  createNewOtp,
  loginUser,
  loginUserWithOtp,
} from "reducers/user";
import useTranslation from "utils/useTranslation";
import "./Login.scss";
import { OtpPopup } from "components/otp-popup/OtpPopup";

const Login = () => {
  var CryptoJS = require("crypto-js");

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [isOtpPopupOpen, setIsOtpPopupOpen] = useState(false);
  const emailCheck = useSelector((state) => state.user.emailCheck);
  const loading = useSelector(({ user }) => user.loading);
  const error = useSelector(({ user }) => user.error);
  const errorOtp = useSelector(({ user }) => user.errorOtp);
  const errorContact = useSelector(({ contacts }) => contacts.error);

  const sendRenewalRequest = async (email) => {
    const res = await dispatch(createRenewContact(email));
    if (res) {
      setIsRequestSent(true);
    }
  };

  const handleLogin = () => {
    // check if cookie present
    const cookie = document.cookie;
    if (cookie) {
      const cookieArr = cookie.split(";");
      const otpChecked = cookieArr.find((c) => c.includes("otpChecked"));
      // decrypt cookie

      if (!!otpChecked) {
        let otpCheckedDecrypted = otpChecked.split("=")[1];
        otpCheckedDecrypted = otpCheckedDecrypted + "=";
        const decryptedExpires = CryptoJS.AES.decrypt(
          otpCheckedDecrypted,
          "SU3XM1L1T4R1"
        ).toString(CryptoJS.enc.Utf8);

        // if cookie present and mail is the same, login
        if (decryptedExpires === email) {
          dispatch(loginUser({ email, password }));
        } else {
          dispatch(checkEmail({ email, password }));
        }
      } else {
        dispatch(checkEmail({ email, password }));
      }
    } else {
      dispatch(checkEmail({ email, password }));
    }
  };

  const handleLoginWithOtp = (otp, isRememberChecked) => {
    dispatch(loginUserWithOtp({ email, password, otp }, isRememberChecked));
  };

  useEffect(() => {
    if (emailCheck === "Email found") {
      dispatch(createNewOtp({ email }));
      setIsOtpPopupOpen(true);
    }
  }, [emailCheck]);

  return (
    <div className="login-popup">
      {!!isOtpPopupOpen && errorOtp !== "expired_account" && (
        <OtpPopup
          setIsOtpPopupOpen={setIsOtpPopupOpen}
          handleLoginWithOtp={handleLoginWithOtp}
          createNewOtp={() => dispatch(createNewOtp({ email }))}
        />
      )}
      {errorOtp === "expired_account" ? (
        <>
          {isRequestSent ? (
            <>
              <h3>{t("richiestaInviata", text)}</h3>
              <p style={{ fontSize: "1rem" }}>
                {t("richiestaAggiornamentoInviata", text)}
              </p>
            </>
          ) : (
            <>
              <h3>{t("credenzialiScadute", text)}</h3>
              <p style={{ fontSize: "1rem" }}>
                {t("richiediAggiornamento", text)}
              </p>
              {errorContact.message === "Contact already exists" && (
                <p
                  style={{
                    fontSize: "1rem",
                    color: "crimson",
                    margin: "0px 0px 8px 0px",
                    fontWeight: 500,
                  }}
                >
                  {t("richiestaGiaInviata", text)}
                </p>
              )}
              <div
                onClick={() => sendRenewalRequest(email)}
                className="btn primary pad hover"
                activeClassName="primary"
                style={{ alignSelf: "center" }}
              >
                {loading ? <Spinner /> : t("rinnova", text)}
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <h3>login into your account</h3>

          <div className="login__form">
            <input
              type="email"
              placeholder="email*"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="password*"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          {(error || emailCheck === "Email not found") && (
            <div className="login-error">{t("mailError", text)}</div>
          )}
          <div
            className="btn tertiary pad center"
            onClick={() => handleLogin()}
          >
            {loading ? <Spinner /> : t("login")}
          </div>
        </>
      )}
    </div>
  );
};

export default Login;

const text = [
  {
    id: "mailError",
    it: "Indirizzo mail/password errati",
    en: "Wrong email/password",
  },
  {
    id: "credenzialiScadute",
    it: "Credenziali scadute",
    en: "Expired credentials",
  },
  {
    id: "richiediAggiornamento",
    it: "Clicca qui per richiedere l'aggiornamento delle credenziali",
    en: "Click here to request the update of the credentials",
  },
  {
    id: "richiestaAggiornamentoInviata",
    it: "La richiesta di aggiornamento è stata inviata. Ti contatteremo al più presto",
    en: "The request for update has been sent. We will contact you as soon as possible",
  },
  {
    id: "richiestaGiaInviata",
    it: "La richiesta è stata già inviata",
    en: "The request has already been sent",
  },
  {
    id: "richiestaInviata",
    it: "Richiesta inviata",
    en: "Request sent",
  },
];
