import { getAllUsers } from "reducers/user";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "utils/useTranslation";
import "../AdminPanel.scss";
import moment from "moment";
import { useState } from "react";
import { Popup } from "components/popup/Popup";
import { MagnifyingGlass } from "assets/icons/icons";
import { updateContact } from "reducers/contacts";
import Select from "components/select/Select";

const contactDetails = [
  {
    label: "distributorName",
    value: "distributorName",
  },
  {
    label: "firstname",
    value: "firstname",
  },
  {
    label: "lastname",
    value: "lastname",
  },
  {
    label: "email",
    value: "email",
  },
  {
    label: "companyName",
    value: "companyName",
  },
  {
    label: "address",
    value: "address",
  },
  {
    label: "telefono",
    value: "phone",
  },
  {
    label: "cellulare",
    value: "mobile",
  },
  {
    label: "postalCode",
    value: "postalCode",
  },
  {
    label: "city",
    value: "city",
  },
  {
    label: "country",
    value: "country",
  },
];

const RequestRow = ({ contact }) => {
  const { t } = useTranslation();
  const account = useSelector((state) => state.user.data.user);
  const levels = useSelector(({ levels }) => levels.data);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDetailsPopupOpen, setIsDetailsPopupOpen] = useState(false);
  const [isLevelChoicePopupOpen, setIsLevelChoicePopupOpen] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);

  const dispatch = useDispatch();
  const handleRefuseRequest = () => {
    dispatch(updateContact(contact.id, { status: "refused" }));
  };
  const handleAcceptRequest = async () => {
    const res = await dispatch(
      updateContact(contact.id, {
        status: "accepted",
        userLevel: selectedLevel,
      })
    );
    if (res) {
      // console.log("chiamata partita accept request getUsers");
      dispatch(getAllUsers());
    }
  };

  return (
    <div className={`user-row`}>
      {isPopupOpen && (
        <Popup
          className="popup__text"
          handleClose={() => setIsPopupOpen(false)}
        >
          <div> {t("confermaRifiuto", text)} </div>
          <div className="popup__button-container">
            <div
              className="btn secondary pad dark"
              onClick={() => setIsPopupOpen(false)}
            >
              {t("annulla")}{" "}
            </div>
            <div
              className="btn primary pad"
              onClick={() => {
                handleRefuseRequest();
                setIsPopupOpen(false);
              }}
            >
              {t("conferma")}{" "}
            </div>
          </div>
        </Popup>
      )}
      {isLevelChoicePopupOpen && (
        <Popup
          className="popup__text"
          handleClose={() => setIsLevelChoicePopupOpen(false)}
        >
          {/* <h4> {t("scegliLivello", text)} </h4> */}
          <Select
            options={levels}
            setSelectedCategory={setSelectedLevel}
            placeholder={t("scegliLivello", text)}
          />
          <div className="popup__button-container">
            <div
              className="btn secondary pad dark"
              onClick={() => setIsLevelChoicePopupOpen(false)}
            >
              {t("annulla")}{" "}
            </div>
            <div
              className={`btn primary pad ${!selectedLevel ? "disabled" : ""}`}
              disabled={!selectedLevel}
              onClick={() => {
                handleAcceptRequest();
                setIsLevelChoicePopupOpen(false);
              }}
            >
              {t("conferma")}{" "}
            </div>
          </div>
        </Popup>
      )}
      {isDetailsPopupOpen && (
        <Popup
          className="popup__text"
          handleClose={() => setIsDetailsPopupOpen(false)}
        >
          <h4>
            {contact?.distributorName
              ? `${t("richiestaDaDistributore", text)}`
              : `${t("richiestaDaUtente", text)}`}
          </h4>
          <div className="popup__details-container">
            <div>
              <span className="popup__details-container__label">
                {t("dataRichiesta", text)}:{" "}
              </span>
              {moment(contact.createdAt).format("DD/MM/YYYY")}
            </div>
            {contactDetails.map(
              (detail, index) =>
                contact[detail.value] && (
                  <div
                    key={`key_contactsdetails_${index}`}
                    style={{ width: detail.width }}
                  >
                    <span className="popup__details-container__label">
                      {t(detail.label, text)}:{" "}
                    </span>
                    {`${contact[detail.value]}`}
                  </div>
                )
            )}
            <div style={{ width: "100%" }}>
              <div className="popup__details-container__label">
                {t("text", text)}
              </div>
              <div style={{ paddingTop: 8 }}>{contact.text}</div>
            </div>
          </div>
        </Popup>
      )}
      <div
        className="row-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{ width: "25%" }}
        >{`${contact?.firstname?.toUpperCase()} ${contact?.lastname?.toUpperCase()}`}</div>
        <div style={{ width: "25%" }}>
          {contact?.type === "renew-user"
            ? t("rinnovo", text)
            : t("nuovoUtente", text)}
        </div>
        <div style={{ width: "25%" }}>
          {contact?.type === "new_user" && account?.role === "admin" && (
            <div>
              {t("richiestaDa", text)}
              {contact?.distributorName
                ? ` ${contact?.distributorName?.toUpperCase()}`
                : ` ${contact?.firstname?.toUpperCase()} ${contact?.lastname?.toUpperCase()}`}
            </div>
          )}
        </div>
        <div
          className="row-container__right-section"
          style={{ width: "25%", display: "flex", alignItems: "center" }}
        >
          {account?.role === "admin" && (
            <>
              <div
                onClick={() => setIsPopupOpen(true)}
                className="btn secondary pad danger"
              >
                {t("rifiuta", text)}
              </div>

              {contact?.type === "renew-user" ? (
                <div
                  onClick={() => handleAcceptRequest()}
                  className="btn tertiary pad hover"
                >
                  {t("rinnova", text)}
                </div>
              ) : (
                <div
                  onClick={() => setIsLevelChoicePopupOpen(true)}
                  className="btn tertiary pad hover"
                >
                  {t("approva", text)}
                </div>
              )}
            </>
          )}
          <MagnifyingGlass
            height={30}
            style={{
              marginLeft: 4,
              marginRight: 20,
              cursor: "pointer",
              fill: "var(--darkGrey)",
            }}
            onClick={() => setIsDetailsPopupOpen(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default RequestRow;

const text = [
  {
    id: "registeredFrom",
    en: "User registered from",
    it: "Utente registrato dal",
  },
  {
    id: "level",
    en: "Level",
    it: "Livello",
  },
  {
    id: "disattiva",
    it: "Disattiva",
    en: "Disable",
  },
  {
    id: "attiva",
    it: "Attiva",
    en: "Enable",
  },

  {
    id: "disattivato",
    it: "Disattivato",
    en: "Disabled",
  },
  {
    id: "confermaRifiuto",
    it: "Sei sicuro di voler rifutare la richiesta ? Questa operazione non può essere annullata.",
    en: "Are you sure you want to refuse the request? This operation cannot be undone.",
  },
  {
    id: "richiestaDa",
    it: "Richiesta da",
    en: "Request from",
  },
  {
    id: "nuovoUtente",
    it: "Nuovo Utente",
    en: "New User",
  },
  {
    id: "rinnovo",
    it: "Rinnovo",
    en: "Renew",
  },
  {
    id: "attivaCorso",
    it: "Attiva corso",
    en: "Enable course",
  },
  {
    id: "richiestaDaUtente",
    it: "Richiesta da Utente",
    en: "Request from user",
  },
  {
    id: "richiestaDaDistributore",
    it: "Richiesta da distributore",
    en: "Request from ditributor",
  },
  {
    id: "firstname",
    it: "nome",
    en: "firstname",
  },
  {
    id: "lastname",
    it: "cognome",
    en: "lastname",
  },
  {
    id: "distributorName",
    it: "Nome distributore",
    en: "Distributor Name",
  },
  {
    id: "email",
    it: "email",
    en: "Email",
  },
  {
    id: "companyName",
    it: "Nome azienda",
    en: "Company name",
  },
  {
    id: "address",
    it: "Indirizzo",
    en: "Address",
  },
  {
    id: "telefono",
    it: "Telefono fisso",
    en: "phone",
  },
  {
    id: "cellulare",
    it: "Cellulare",
    en: "mobile",
  },
  {
    id: "postalCode",
    it: "Codice postale",
    en: "Zip Address",
  },
  {
    id: "city",
    it: "Città",
    en: "city",
  },
  {
    id: "country",
    it: "Nazione",
    en: "country",
  },
  {
    id: "text",
    it: "Testo richiesta",
    en: "Request text",
  },
  {
    id: "scegliLivello",
    it: "Scegli livello dell'utente",
    en: "Choose user level",
  },
  {
    id: "dataRichiesta",
    it: "Data richiesta",
    en: "Request date",
  },
  {
    id: "rinnova",
    it: "Rinnova",
    en: "Renew",
  },
  {
    id: "rifiuta",
    it: "rifiuta",
    en: "reject",
  },
  {
    id: "approva",
    it: "approva",
    en: "approve",
  },
];
