import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getAllCourses, getUserActiveCourses } from "reducers/courses";
import useTranslation from "utils/useTranslation";
import "./Courses.scss";
import Placeholders from "components/placeholder/Placeholder";
import { baseUrl } from "config/server";

const Courses = () => {
  const courses = useSelector(({ courses }) =>
    courses.data?.length > 0 ? courses.data : courses.userCourses
  );
  // const userCourses = useSelector(({ courses }) => courses.userCourses);
  const user = useSelector(({ user }) => user.data.user);
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const isAdmin = user?.role === "admin";
  const firstCategory = [];
  const secondCategory = [];
  const loading = useSelector(({ courses }) => courses.loading);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!user) {
      if (isAdmin) {
        dispatch(getAllCourses());
      } else {
        dispatch(getUserActiveCourses(user.userId));
      }
    }
  }, [user]);

  const options = courses.map((element, i) =>
    isIta ? element.categoryIt : element.categoryEn
  );

  return (
    <div className="account-page__courses-section container">
      {loading ? (
        <Placeholders type={"coursesList"} number={1} />
      ) : (
        <div className="courses__list ">
          <div className="courses-list subsection-pad">
            {courses.map((element) => {
              if (
                (isIta
                  ? element.categoryIt
                  : element.categoryEn
                ).toLowerCase() === (isIta ? "operatore" : "operator")
              ) {
                firstCategory.push(element);
              } else if (
                (isIta
                  ? element.categoryIt
                  : element.categoryEn
                ).toLowerCase() === (isIta ? "manutenzione" : "maintenance")
              ) {
                secondCategory.push(element);
              }
              return "";
            })}
            <div className="courses-list__category-section">
              <h2>{isIta ? "OPERATORE" : "OPERATOR"}</h2>
              <div className="courses-list__course-category">
                {firstCategory.map((element, i) => (
                  <div
                    key={`key_firstcategory_${element.courseCode}_${i}`}
                    className="courses-list__course"
                  >
                    <Link to={`/account/courses/${element.courseCode}`}>
                      <img src={baseUrl + "/" + element.imgUrl} alt="" />
                    </Link>
                    <h4 className="no-mar">
                      {isIta ? element.nameIt : element.nameEn}
                    </h4>
                  </div>
                ))}
              </div>
              <h2 style={{ marginTop: "50px" }}>
                {isIta ? "MANUTENZIONE" : "MAINTENANCE"}
              </h2>
              <div className="courses-list__course-category">
                {secondCategory.map((element, i) => (
                  <div
                    key={`key_secondcategory_${element.courseCode}_${i}`}
                    className="courses-list__course"
                  >
                    <Link to={`/account/courses/${element.courseCode}`}>
                      <img src={baseUrl + "/" + element.imgUrl} alt="" />
                    </Link>
                    <h4 className="no-mar">
                      {isIta ? element.nameIt : element.nameEn}
                    </h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Courses;
