import { useDispatch, useSelector } from "react-redux";
import useTranslation from "utils/useTranslation";
import "../AdminPanel.scss";

import {
  createUserCourse,
  updateUserCourse,
  createUserFirmware,
  updateUserFirmware,
  createUserDocument,
  updateUserDocument,
  createUserSoftware,
  updateUserSoftware,
  createUserPricelist,
  updateUserPricelist,
  activeUserAllCourses,
  activeUserAllFirmware,
  activeUserAllDocuments,
  activeUserAllSoftware,
  activeUserAllPricelists,
  activeUserAllDocumentsCourses,
  activeUserAllDocumentsUser,
} from "reducers/user";
import { Search } from "assets/icons/icons";

const UserDetailRow = ({
  detail,
  account,
  userCourses,
  userFirmware,
  userSoftware,
  userPricelist,
  userDocuments,
  user,
  detailsType,
  isActiveAll,
  setFilter,
  filter,
}) => {
  const { t } = useTranslation();
  const activeLanguage = useSelector(({ language }) => language);
  const isIta = activeLanguage === "it";
  const dispatch = useDispatch();
  const updateUserDetailStatus = (userDetailId, status) => {
    if (detailsType === "courses") {
      dispatch(
        updateUserCourse(userDetailId, {
          isActive: status,
        })
      );
    } else if (detailsType === "firmware") {
      dispatch(
        updateUserFirmware(userDetailId, {
          isActive: status,
        })
      );
    } else if (detailsType === "documents") {
      dispatch(
        updateUserDocument(userDetailId, {
          isActive: status,
        })
      );
    } else if (detailsType === "documentsCourses") {
      dispatch(
        updateUserDocument(userDetailId, {
          isActive: status,
        })
      );
    } else if (detailsType === "software") {
      dispatch(
        updateUserSoftware(userDetailId, {
          isActive: status,
        })
      );
    } else if (detailsType === "pricelist") {
      dispatch(
        updateUserPricelist(userDetailId, {
          isActive: status,
        })
      );
    }
  };

  const createNewUserCourse = (userId, detail) => {
    // console.log("DETAIL", detail);
    if (detailsType === "courses") {
      dispatch(createUserCourse(userId, detail.courseCode));
    } else if (detailsType === "firmware") {
      dispatch(createUserFirmware(userId, detail.firmwareCode));
    } else if (detailsType === "documents") {
      dispatch(createUserDocument(userId, detail.documentCode));
    } else if (detailsType === "documentsCourses") {
      dispatch(createUserDocument(userId, detail.documentCode));
    } else if (detailsType === "software") {
      dispatch(createUserSoftware(userId, detail.softwareCode));
    } else if (detailsType === "pricelist") {
      dispatch(createUserPricelist(userId, detail.pricelistCode));
    }
  };

  const activateUserAllDetails = (userId) => {
    if (detailsType === "courses") {
      dispatch(activeUserAllCourses(userId));
    } else if (detailsType === "firmware") {
      dispatch(activeUserAllFirmware(userId));
    } else if (detailsType === "documents") {
      dispatch(activeUserAllDocumentsUser(userId));
    } else if (detailsType === "documentsCourses") {
      dispatch(activeUserAllDocumentsCourses(userId));
    } else if (detailsType === "software") {
      dispatch(activeUserAllSoftware(userId));
    } else if (detailsType === "pricelist") {
      dispatch(activeUserAllPricelists(userId));
    }
  };

  // check se attivo
  const activatedDetail =
    detailsType === "courses"
      ? userCourses?.find(
          (userCourse) =>
            (userCourse.courseId === detail.id && !!userCourse.isActive) ||
            (userCourse.courseCode === detail.courseCode &&
              !!userCourse.isActive)
        )
      : detailsType === "documents"
      ? userDocuments?.find(
          (userDocument) =>
            userDocument.documentCode === detail.documentCode &&
            !!userDocument.isActive
        )
      : detailsType === "documentsCourses"
      ? userDocuments?.find(
          (userDocument) =>
            userDocument.documentCode === detail.documentCode &&
            !!userDocument.isActive
        )
      : detailsType === "firmware"
      ? userFirmware?.find(
          (userFirmware) =>
            userFirmware.firmwareCode === detail.id && !!userFirmware.isActive
        )
      : detailsType === "software"
      ? userSoftware?.find(
          (userSoftware) =>
            userSoftware.softwareCode === detail.id && !!userSoftware.isActive
        )
      : detailsType === "pricelist"
      ? userPricelist?.find(
          (userPricelist) =>
            userPricelist.pricelistCode === detail.pricelistCode &&
            !!userPricelist.isActive
        )
      : "";

  // check se è presente
  const deactivatedDetail =
    detailsType === "courses"
      ? userCourses?.find(
          (userCourse) =>
            (userCourse.courseId === detail.id && !!userCourse.isActive) ||
            (userCourse.courseCode === detail.courseCode &&
              !userCourse.isActive)
        )
      : detailsType === "documents"
      ? userDocuments?.find(
          (userDocument) =>
            userDocument.documentCode === detail.id && !userDocument.isActive
        )
      : detailsType === "documentsCourses"
      ? userDocuments?.find(
          (userDocument) =>
            userDocument.documentCode === detail.id && !userDocument.isActive
        )
      : detailsType === "firmware"
      ? userFirmware?.find(
          (userFirmware) =>
            userFirmware.firmwareCode === detail.id && !userFirmware.isActive
        )
      : detailsType === "software"
      ? userSoftware?.find(
          (userSoftware) =>
            userSoftware.softwareCode === detail.id && !userSoftware.isActive
        )
      : detailsType === "pricelist"
      ? userPricelist?.find(
          (userPricelist) =>
            userPricelist.pricelistCode === detail.id && !userPricelist.isActive
        )
      : "";

  return (
    <>
      {!!isActiveAll ? (
        <div className="course-row">
          <div
            className="admin-panel__title-container admin-panel__title-container__title-search-container"
            style={{ justifyContent: "flex-end", margin: "5px 0px" }}
          >
            <div className="searchbar-container">
              <input
                type="text"
                value={filter}
                placeholder="search"
                className="searchbar-container__searchbar-input-active"
                onChange={(e) => setFilter(e.target.value)}
              />
              <Search className="searchbar-container__searchbar-icon" />
            </div>
          </div>

          <div
            onClick={() => activateUserAllDetails(user?.userId)}
            className="btn tertiary pad hover"
          >
            {t("attivaTutti", text)}
          </div>
        </div>
      ) : (
        <div className="course-row">
          {detail.productCode ? (
            <div>
              {`   ${isIta ? detail.nameIt : detail.nameEn} - ${t(
                "productCode",
                text
              )}
          ${detail.productCode}`}
            </div>
          ) : (
            <div>{isIta ? detail.nameIt : detail.nameEn}</div>
          )}

          <div>
            {activatedDetail ? (
              <div
                onClick={() =>
                  updateUserDetailStatus(activatedDetail.id, false)
                }
                className="btn secondary pad danger"
              >
                {t("disattiva", text)}
              </div>
            ) : (
              <div
                onClick={() =>
                  deactivatedDetail
                    ? updateUserDetailStatus(deactivatedDetail.id, true)
                    : createNewUserCourse(user.userId, detail)
                }
                className="btn tertiary pad hover"
              >
                {t("attiva", text)}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default UserDetailRow;

const text = [
  {
    id: "disattiva",
    it: "Disattiva",
    en: "Disable",
  },
  {
    id: "attiva",
    it: "Attiva",
    en: "Enable",
  },
  {
    id: "disattivaCorso",
    it: "Disattiva corso",
    en: "Disable course",
  },
  {
    id: "disattivato",
    it: "Disattivato",
    en: "Disabled",
  },
  {
    id: "corsoDisattivato",
    it: "Corso Disattivato",
    en: "Course Disabled",
  },
  {
    id: "vediCorsi",
    it: "Vedi Corsi",
    en: "View Courses",
  },
  {
    id: "mostraUtentiDisattivati",
    it: "Mostra utenti disattivati",
    en: "Show disabled users",
  },
  {
    id: "nascondiUtentiDisattivati",
    it: "Nascondi utenti disattivati",
    en: "Hide disabled users",
  },
  {
    id: "confermaDisattivazione",
    it: "Sei sicuro di voler disattivare l'utente? Questa operazione non può essere annullata.",
    en: "Are you sure you want to disable the user? This operation cannot be undone.",
  },
  {
    id: "confermaRifiuto",
    it: "Sei sicuro di voler rifutare la richiesta ? Questa operazione non può essere annullata.",
    en: "Are you sure you want to refuse the request? This operation cannot be undone.",
  },
  {
    id: "requestFromDistributor",
    it: "richiedi accesso nuovo utente",
    en: "request access new user",
  },
  {
    id: "richiestaFatta",
    it: "Richiesta fatta il",
    en: "Request made on",
  },
  {
    id: "richiestaDa",
    it: "Richiesta da",
    en: "Request from",
  },
  {
    id: "nuovoUtente",
    it: "Nuovo Utente",
    en: "New User",
  },
  {
    id: "rinnovo",
    it: "Rinnovo",
    en: "Renew",
  },
  {
    id: "attivaCorso",
    it: "Attiva corso",
    en: "Enable course",
  },
  {
    id: "productCode",
    it: "Codice Prodotto:",
    en: "Product Code:",
  },
  {
    id: "attivaTutti",
    it: "Attiva tutti",
    en: "Enable all",
  },
];
